import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {getIssuesByStatus} from '../../actions/issuesByStatus.actions';
import IssuesByStatus from './issuesByStatus.component';

function IssuesByStatusContainer(props) {
    const {dateFilters, getIssuesByStatus, issuesByStatusStatistic} = props;

    useEffect(() => {
        getIssuesByStatus(dateFilters);
    }, [dateFilters, getIssuesByStatus]);

    const colors = [
        '#ec412c', '#2196f3', '#4caf50', '#9c27b0', '#3f51b5', '#ff9800', '#00E396', '#008FFB', '#00EA7B',
    ];

    const issues = issuesByStatusStatistic.items ?
        [
            {
                title: 'На модерации',
                value: issuesByStatusStatistic.items.ModerationPrivate,
                color: colors[0],
            },
            {
                title: 'На рассмотрении',
                value: issuesByStatusStatistic.items.InQuestionPrivate,
                color: colors[1],
            },
            {
                title: 'С ответом',
                value: issuesByStatusStatistic.items.WithAnswerPrivate,
                color: colors[2],
            },
        ] :
        [];

    const issuesAmount = issues.reduce((a, b) => a + (b.value || 0), 0);
    const dataLoading = !issuesByStatusStatistic.items || issuesByStatusStatistic.isFetching;

    return (
        <IssuesByStatus
            issues={issues}
            total={issuesByStatusStatistic.items.total}
            issuesAmount={issuesAmount}
            dataLoading={dataLoading}
        />
    );
}

const mapStateToProps = (state) => {
    const {
        statistic: {
            issuesByStatusStatistic,
        },
    } = state;

    return {issuesByStatusStatistic};
};

export default connect(mapStateToProps, {
    getIssuesByStatus,
})(IssuesByStatusContainer);
