import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getCategoriesRating} from '../../actions/categoriesRating.actions';
import CategoriesRating from './categoriesRating.component';

const perPage = 10;

function CategoriesRatingContainer(props) {
    const {dateFilters, getCategoriesRating, categoriesRatingData} = props;

    const [nextPage, setNextPage] = useState(1);
    const dataLoading = !categoriesRatingData.items.length || categoriesRatingData.isFetching;

    useEffect(() => {
        const filters = {
            order_by_statistic: 'total',
            order_direction: 'desc',
            per_page: perPage * nextPage
        };

        getCategoriesRating({...dateFilters, ...filters});
    }, [dateFilters, getCategoriesRating, nextPage]);

    return (
        <CategoriesRating
            categoriesRating={categoriesRatingData.items}
            nextPage={setNextPage}
            perPage={perPage}
            dataLoading={dataLoading}
            meta={categoriesRatingData.meta}
        />
    );
}

const mapStateToProps = (state) => {
    const {
        statistic: {
            categoriesRating: categoriesRatingData,
        },
    } = state;

    return {categoriesRatingData};
};

export default connect(mapStateToProps, {
    getCategoriesRating,
})(CategoriesRatingContainer);
