import React from 'react';
import styles from './legendItem.module.css';

function PercentWithLabel({color, title, data}) {
    return (
        <tr className={styles.container}>
            <td className={styles.titleWrapper}>
                <div
                    style={{backgroundColor: color}}
                    className={styles.circle}
                />
                <p className={styles.title}>
                    {title}
                </p>
            </td>
            {
                data &&
                <td className={styles.data}>
                    {data}
                </td>
            }
        </tr>
    );
}

export default PercentWithLabel;
