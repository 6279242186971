import React from 'react';
import cn from 'classnames';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';
import styles from './executorsRating.module.css';

function ExecutorsRating(props) {
    const {executorsRating, dataLoading} = props;

    return (
        <section
            className={cn(styles.container, {
                'loading-container': dataLoading,
            })}
        >
            <h2 className={styles.title}>
                Рейтинг исполнителей
            </h2>
            <div className={styles.tables}>
                <table className={styles.table}>
                    <thead className={styles.thead}>
                    <tr>
                        <td className={styles.tdNumber}/>
                        <td className={cn(styles.theadTd, styles.theadTdSphere)}>Исполнитель</td>
                        <td className={styles.theadTd}>Кол-во заявок</td>
                        <td className={styles.theadTd}>Кол-во с ответом</td>
                    </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                    {executorsRating.map((issue, index) => (
                        <tr className={styles.tr} key={issue.id}>
                            <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                            <td className={cn(styles.td, styles.tdSphere)}>{issue.name}</td>
                            <td className={styles.td}>{issue.statistic.total}</td>
                            <td className={styles.td}>{issue.statistic.WithAnswerPrivate}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <CssLoader/>
        </section>
    );
}

export default ExecutorsRating;
