import {
    getIssuesByStatusRequest,
    getIssuesByStatusSuccess,
    getIssuesByStatusFailure,
} from '../actions/issuesByStatus.actions';

const INITIAL_STATE = {
    items: [],
    isFetching: false,
    error: null,
};

const issuesByStatusStatistic = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case getIssuesByStatusRequest:
            return {
                ...state,
                isFetching: true,
            };
        case getIssuesByStatusSuccess:
            return {
                ...state,
                isFetching: false,
                items: action.payload.items,
            };
        case getIssuesByStatusFailure:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default issuesByStatusStatistic;
