import axios from '../utils/axios';

export const getIssuesByStatusRequest = 'GET_ISSUES_BY_STATUS_REQUEST';
export const getIssuesByStatusSuccess = 'GET_ISSUES_BY_STATUS_SUCCESS';
export const getIssuesByStatusFailure = 'GET_ISSUES_BY_STATUS_FAILURE';

export const issuesByStatusRequest = () => ({
    type: getIssuesByStatusRequest,
});

export const issuesByStatusSuccess = (items) => ({
    type: getIssuesByStatusSuccess,
    payload: {
        items,
    },
});

export const issuesByStatusFailure = (error) => ({
    type: getIssuesByStatusFailure,
    payload: {
        error,
    },
});

export const getIssuesByStatus = (filters) => (dispatch) => {
    dispatch(issuesByStatusRequest());

    return axios('/api/statistic-czn/issues/by-status', {
        params: filters,
    })
        .then((response) => {
            dispatch(issuesByStatusSuccess(response.data));
        })
        .catch((err) => {
            dispatch(issuesByStatusFailure(err));
        });
};
