import axios from 'axios';

const url = window.location.href;
const outerUrl = 'http://rostrud.test7.happydesk.ru/panel/login';
const tokenParam = '?authToken=';
const token = url.indexOf(tokenParam) !== -1
    ? url.slice(url.indexOf(tokenParam) + tokenParam.length)
    : null;

if (!token) {
    window.location.href = outerUrl;
} else {
    localStorage.setItem('authToken', token);
}

if (localStorage.authToken) {
    axios.defaults.headers.common['x-auth-token'] = localStorage.authToken;
}

axios.interceptors.response.use(null, (error) => {
    if (error.response && error.response.status === 401) {
        window.location.href = outerUrl;
    }

    return Promise.reject(error);
});

export default axios;
