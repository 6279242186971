import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {getExecutorsRating} from '../../actions/executorsRating.actions';
import ExecutorsRating from './executorsRating.component';

function ExecutorsRatingContainer(props) {
    const {dateFilters, getExecutorsRating, executorsRatingData} = props;

    const executorsRating = executorsRatingData.items || [];
    const dataLoading = !executorsRating.length || executorsRating.isFetching;

    useEffect(() => {
        const filters = {
            order_by_statistic: 'total',
            order_direction: 'desc',
        };

        getExecutorsRating({...dateFilters, ...filters});
    }, [dateFilters, getExecutorsRating]);

    return (
        <ExecutorsRating
            executorsRating={executorsRating}
            dataLoading={dataLoading}
        />
    );
}

const mapStateToProps = (state) => {
    const {
        statistic: {
            executorsRating: executorsRatingData,
        },
    } = state;

    return {executorsRatingData};
};

export default connect(mapStateToProps, {
    getExecutorsRating,
})(ExecutorsRatingContainer);
