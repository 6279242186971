import React from 'react';
import cn from 'classnames';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';
import styles from './regionsRating.module.css';

let currentPage = 1;

function RegionsRating(props) {
    const {regionsRating, dataLoading, meta, nextPage, perPage} = props,
        {totalCount} = meta,
        pageCount = (totalCount - (totalCount % perPage)) / perPage;

    return (
        <section
            className={cn(styles.container, {
                'loading-container': dataLoading,
            })}
        >
            <h2 className={styles.title}>
                Регионы с наибольшим количеством заявок
            </h2>
            <div className={styles.tables}>
                <table className={styles.table}>
                    <thead className={styles.thead}>
                    <tr>
                        <td className={styles.tdNumber}/>
                        <td className={cn(styles.theadTd, styles.theadTdSphere)}>Регион</td>
                        <td className={styles.theadTd}>Кол-во заявок</td>
                        <td className={styles.theadTd}>Кол-во с ответом</td>
                    </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                    {
                        regionsRating.map((region, index) => (
                            <tr className={styles.tr} key={region.id}>
                                <td className={cn(styles.td, styles.tdNumber)}>{`${index + 1}.`}</td>
                                <td className={cn(styles.td, styles.tdSphere)}>{region.region}</td>
                                <td className={styles.td}>{region.statistic.total}</td>
                                <td className={styles.td}>{region.statistic.WithAnswerPrivate}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                {
                    currentPage <= pageCount &&
                    <div className={styles.showMore} onClick={() => {
                        nextPage(++currentPage);
                    }}>
                        <span>Показать больше</span>
                    </div>
                }
            </div>
            <CssLoader/>
        </section>
    );
}

export default RegionsRating;
