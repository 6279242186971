import React from 'react';
import Analytics from './components/analytics/analytics.container';
import './assets/css/main.css';

function App() {
    return (
        <div className="app">
            <Analytics />
        </div>
    );
}

export default App;
