import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Analytics from './analytics.component';

function AnalyticsContainer() {
  const [filters, setFilters] = useState({
    created_from: null,
    created_to: null,
  });

  const dateId = 'analytics-date';

  const handleDatesBlur = (startDate, endDate) => {
    let start = null;
    let end = null;

    if (startDate) {
      start = new Date(moment(startDate).valueOf()).setHours(0) / 1000;
    }

    if (endDate) {
      end = new Date(moment(endDate).valueOf());
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end /= 1000;
    }

    setFilters({
      ...filters,
      created_from: start,
      created_to: end,
    });
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = 'https://rostrud.happydesk.ru/panel/login';
    }
  };

  return (
    <Analytics
        filters={filters}
        dateId={dateId}
        handleDatesBlur={handleDatesBlur}
        handleBack={handleBack}
    />
  );
}

export default connect(null, null)(AnalyticsContainer);
