import axios from '../utils/axios';

export const getGetCategoriesRatingRequest = 'GET_CATEGORIES_RATING_REQUEST';
export const getGetCategoriesRatingSuccess = 'GET_CATEGORIES_RATING_SUCCESS';
export const getGetCategoriesRatingFailure = 'GET_CATEGORIES_RATING_FAILURE';

export const getCategoriesRatingRequest = () => ({
    type: getGetCategoriesRatingRequest,
});

export const getCategoriesRatingSuccess = (data) => ({
    type: getGetCategoriesRatingSuccess,
    payload: {
        data,
    },
});

export const getCategoriesRatingFailure = (error) => ({
    type: getGetCategoriesRatingFailure,
    payload: {
        error,
    },
});

export const getCategoriesRating = (filters) => (dispatch) => {
    dispatch(getCategoriesRatingRequest());

    return axios('/api/statistic-czn/issues/by-category', {
        params: filters,
    })
        .then((response) => {
            dispatch(getCategoriesRatingSuccess(response.data));
        })
        .catch((err) => {
            dispatch(getCategoriesRatingFailure(err));
        });
};
