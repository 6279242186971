import axios from '../utils/axios';

export const getGetRegionsRatingRequest = 'GET_REGIONS_RATING_REQUEST';
export const getGetRegionsRatingSuccess = 'GET_REGIONS_RATING_SUCCESS';
export const getGetRegionsRatingFailure = 'GET_REGIONS_RATING_FAILURE';

export const getRegionsRatingRequest = () => ({
    type: getGetRegionsRatingRequest,
});

export const getRegionsRatingSuccess = (data) => ({
    type: getGetRegionsRatingSuccess,
    payload: {
        data,
    },
});

export const getRegionsRatingFailure = (error) => ({
    type: getGetRegionsRatingFailure,
    payload: {
        error,
    },
});

export const getRegionsRating = (filters) => (dispatch) => {
    dispatch(getRegionsRatingRequest());

    return axios('/api/statistic-czn/issues/by-region', {
        params: filters,
    })
        .then((response) => {
            dispatch(getRegionsRatingSuccess(response.data));
        })
        .catch((err) => {
            dispatch(getRegionsRatingFailure(err));
        });
};
