import React from 'react';

import styles from './downloadIssuesList.module.css';
import DateRangePicker from "../../ui-kit/datePicker/datePickerRange.component";
import DownloadIcon from '../../assets/images/download.svg';
import DualRing from '../../assets/images/dualRing.svg';

const DownloadIssuesListComponent = props => {
    const {
        handleChangeDateFilterFull,
        downloadIssuesFullReport,
        state
    } = props;

    return <div className={styles.container}>
        <div className={styles.oneContainer}>
            <h2 className={styles.title}>
                Скачать список заявок
            </h2>
            <div className={styles.filters}>
                <DateRangePicker
                    id="report-datepicker"
                    onChange={handleChangeDateFilterFull}
                    startDate={state.reportValues.created_from}
                    endDate={state.reportValues.created_to}
                    className={styles.filterDates}
                />
                <button
                    type="button"
                    className={styles.button}
                    onClick={() => downloadIssuesFullReport('excel')}
                    disabled={state.fullReportGeneratingInProgress}
                >
                    {
                        state.fullReportGeneratingInProgress
                            ? <img className={styles.button_icon} src={DualRing} alt="logo" />
                            : <img className={styles.button_icon} src={DownloadIcon} alt="logo" />
                    }
                    Скачать отчет
                </button>
            </div>
        </div>
    </div>
};

export default DownloadIssuesListComponent;