import {
  getGetExecutorsRatingRequest,
  getGetExecutorsRatingSuccess,
  getGetExecutorsRatingFailure,
} from '../actions/executorsRating.actions';

const INITIAL_STATE = {
  items: [],
  isFetching: false,
  error: null,
};

const executorsRating = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case getGetExecutorsRatingRequest:
    return {
      ...state,
      isFetching: true,
    };
  case getGetExecutorsRatingSuccess:
    return {
      ...state,
      isFetching: false,
      items: action.payload.items,
    };
  case getGetExecutorsRatingFailure:
    return {
      ...state,
      isFetching: false,
      error: action.payload.error,
    };
  default:
    return state;
  }
};

export default executorsRating;
