import {createAction} from 'redux-actions';

import axios from '../utils/axios';

export const generateAnalyticsIssuesFullReportRequest = createAction('GENERATE_ISSUES_FULL_REPORT_REQUEST');
export const generateAnalyticsIssuesFullReportSuccess = createAction('GENERATE_ISSUES_FULL_REPORTSUCCESS');
export const generateAnalyticsIssuesFullReportFailure = createAction('GENERATE_ISSUES_FULL_REPORT_FAILURE');

export const generateAnalyticsIssuesFullReport = (type, filters) => dispatch => {
    dispatch(generateAnalyticsIssuesFullReportRequest(type));

    return axios(`/api/report/czn/issues/${type}`, {
        params: filters,
    })
        .then((response) => {
            dispatch(generateAnalyticsIssuesFullReportSuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch((err) => {
            dispatch(generateAnalyticsIssuesFullReportFailure(err));

            return Promise.reject(err);
        });
};

export const checkAnalyticsIssuesFullReportReadyRequest = createAction('CHECK_ISSUES_FULL_REPORT_REQUEST');
export const checkAnalyticsIssuesFullReportReadySuccess = createAction('CHECK_ISSUES_FULL_REPORT_SUCCESS');
export const checkAnalyticsIssuesFullReportReadyFailure = createAction('CHECK_ISSUES_FULL_REPORT_FAILURE');

export const checkAnalyticsIssuesFullReportReady = dates => dispatch => {
    dispatch(checkAnalyticsIssuesFullReportReadyRequest(dates));

    return axios('/api/report/czn/issues/check', {
        params: dates,
    })
        .then((response) => {
            dispatch(checkAnalyticsIssuesFullReportReadySuccess(response.data));

            return Promise.resolve(response.data);
        })
        .catch((err) => {
            dispatch(checkAnalyticsIssuesFullReportReadyFailure(err));

            return Promise.resolve(err);
        });
};

export const downloadByTokenRequest = createAction('DOWNLOAD_BY_TOKEN_REQUEST');

export const downloadByToken = token => dispatch => {
    dispatch(downloadByTokenRequest(token));

    window.open(`/api/storage/download/${token}`);
};