import axios from '../utils/axios';

export const getGetExecutorsRatingRequest = 'GET_EXECUTORS_RATING_REQUEST';
export const getGetExecutorsRatingSuccess = 'GET_EXECUTORS_RATING_SUCCESS';
export const getGetExecutorsRatingFailure = 'GET_EXECUTORS_RATING_FAILURE';

export const getExecutorsRatingRequest = () => ({
  type: getGetExecutorsRatingRequest,
});

export const getExecutorsRatingSuccess = (items) => ({
  type: getGetExecutorsRatingSuccess,
  payload: {
    items,
  },
});

export const getExecutorsRatingFailure = (error) => ({
  type: getGetExecutorsRatingFailure,
  payload: {
    error,
  },
});

export const getExecutorsRating = (filters) => (dispatch) => {
  dispatch(getExecutorsRatingRequest());

  return axios('/api/statistic-czn/issues/by-executor', {
    params: {
      ...filters,
      is_selected_executors: 1
    },
  })
    .then((response) => {
      dispatch(getExecutorsRatingSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getExecutorsRatingFailure(err));
    });
};