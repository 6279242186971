import {
    getGetRegionsRatingRequest,
    getGetRegionsRatingSuccess,
    getGetRegionsRatingFailure,
} from '../actions/regionsRating.actions';

const INITIAL_STATE = {
    items: [],
    isFetching: false,
    error: null,
    meta: {},
};

const regionsRating = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case getGetRegionsRatingRequest:
            return {
                ...state,
                isFetching: true,
            };
        case getGetRegionsRatingSuccess:
            return {
                ...state,
                isFetching: false,
                items: action.payload.data.items,
                meta: action.payload.data._meta,
            };
        case getGetRegionsRatingFailure:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default regionsRating;
