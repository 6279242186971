import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
    generateAnalyticsIssuesFullReport,
    checkAnalyticsIssuesFullReportReady,
    downloadByToken
} from '../../actions/statisticActions';

import DownloadIssuesListComponent from "./downloadIssuesList.component";

const checkReportIntervalTime = 1000;
const checkReportMaxTime = 10000000;

class DownloadIssuesListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullReportGeneratingInProgress: false,
            reportForm: {
                created_from: null,
                created_to: null,
                row: null,
                column: null,
                district: null,
                region: null,
            },
            reportFormFull: {
                created_from: null,
                created_to: null,
            },
            reportValues: {
                created_from: null,
                created_to: null,
                created_from_full: null,
                created_to_full: null,
                created_from_comfort: null,
                created_to_comfort: null,
                row: null,
                column: null,
                district: null,
                region: null,
            }
        };

        this.handleChangeDateFilterFull = this.handleChangeDateFilterFull.bind(this);
        this.downloadIssuesFullReport = this.downloadIssuesFullReport.bind(this);
    }

    handleChangeDateFilterFull(start, end) {
        const dates = {};
        const datesVal = {};
        const {reportValues} = this.state;
        const {reportFormFull} = this.state;
        const startStart = start ? start.clone().hour(0).minute(0).second(0) : null;
        const endEnd = end ? end.clone().hour(23).minute(59).second(59) : null;

        if (startStart) {
            dates.created_from_full = startStart;
            datesVal.created_from = startStart.format('X');
        }
        if (endEnd) {
            dates.created_to_full = endEnd;
            datesVal.created_to = endEnd.format('X');
        }
        if (!startStart && !endEnd) {
            dates.created_from_full = null;
            datesVal.created_from = null;
            dates.created_to_full = null;
            datesVal.created_to = null;
        }

        this.setState({
            reportValues: {
                ...reportValues,
                ...dates,
            },
            reportFormFull: {
                ...reportFormFull,
                ...datesVal,
            },
        });
    }

    downloadIssuesFullReport(type) {
        if (this.state.fullReportGeneratingInProgress) {
            return false;
        }

        this.setState({
            fullReportGeneratingInProgress: true,
        });

        return this.props.generateAnalyticsIssuesFullReport(type, this.state.reportFormFull)
            .then((response) => {
                if (response === true) {
                    return new Promise((resolve, reject) => {
                        this.interval = setInterval(() => {
                            const dates = this.state.reportFormFull;
                            this.props.checkAnalyticsIssuesFullReportReady(dates)
                                .then((response) => {
                                    if (response) {
                                        clearInterval(this.interval);

                                        resolve(response);
                                    }
                                });
                        }, checkReportIntervalTime);

                        setTimeout(() => {
                            clearInterval(this.interval);

                            // eslint-disable-next-line prefer-promise-reject-errors
                            reject('max time exceeded');
                        }, checkReportMaxTime);
                    });
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Report generation error');
            })
            .then((token) => {
                this.props.downloadByToken(token);
            })
            .catch((err) => {
                console.error('error ', err);
            })
            .finally(() => {
                this.setState({
                    fullReportGeneratingInProgress: false,
                });
            });
    }

    render() {
        return <DownloadIssuesListComponent
            downloadIssuesFullReport={this.downloadIssuesFullReport}
            handleChangeDateFilterFull={this.handleChangeDateFilterFull}
            state={this.state}
        />
    }
}

export default connect(null, {
    generateAnalyticsIssuesFullReport,
    checkAnalyticsIssuesFullReportReady,
    downloadByToken
})(DownloadIssuesListContainer);