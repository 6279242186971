import {combineReducers} from 'redux';
import executorsRating from './executorsRating.reducer';
import categoriesRating from './categoriesRating.reducer';
import regionsRating from './regionsRating.reducer';
import issuesByStatusStatistic from './issuesByStatus.reducer';

const mainReducer = combineReducers({
    statistic: combineReducers({
        executorsRating,
        categoriesRating,
        regionsRating,
        issuesByStatusStatistic
    }),
});

export default mainReducer;