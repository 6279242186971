import React from 'react';

import DatePickerRange from '../../ui-kit/datePicker/datePickerRange.component';
import DownloadIssuesListContainer from "../downloadIssuesList/downloadIssuesList.container";
import ExecutorsRating from "../executorsRating/executorsRating.container";
import CategoriesRating from "../categoriesRating/categoriesRating.container";
import RegionsRating from "../regionsRating/regionsRating.container";
import IssuesByStatus from "../issuesByStatus/issuesByStatus.container";

import styles from './analytics.module.css';

function Analytics(props) {
    const {
        filters,
        handleDatesBlur,
        handleBack,
        dateId,
    } = props;

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.buttonWrapper}>
                    <button
                        type="button"
                        className={styles.button}
                        onClick={() => handleBack()}
                    >
                        Вернуться назад
                    </button>
                </div>
                <section className={styles.header}>
                    <div className={styles.wrapperHeader}>
                        <div className={styles.headerText}>
                            <h1 className={styles.title}>
                                Статистика
                            </h1>
                            <p>
                                Основные показатели по работе с заявками
                            </p>
                        </div>
                        <DatePickerRange
                            onBlur={handleDatesBlur}
                            id={dateId}
                            className={styles.filterDates}
                        />
                    </div>
                </section>
            </div>
            <div className={styles.wrapper}>
                <IssuesByStatus
                    dateFilters={filters}
                />
                <CategoriesRating
                    dateFilters={filters}
                />
                <RegionsRating
                    dateFilters={filters}
                />
                <ExecutorsRating
                    dateFilters={filters}
                />
                <DownloadIssuesListContainer/>
            </div>
        </div>
    );
}

export default Analytics;
