import React from 'react';
import cn from 'classnames';
import {PieChart} from 'react-minimal-pie-chart';
import declOfNum from '../../utils/declOfNum';
import styles from './issuesByStatus.module.css';
import LegendItem from '../../ui-kit/legendItem/legendItem.component';
import CssLoader from '../../ui-kit/cssLoader/cssLoader.component';

function issuesByStatus(props) {
    const {
        issues = [],
        issuesAmount,
        dataLoading,
        total,
    } = props;

    return (
        <section
            className={cn(styles.container, {
                'loading-container': dataLoading,
            })}
        >
            <h2 className={styles.title}>
                Количество заявок по статусу
            </h2>
            <div className={styles.chartContainer}>
                <div className={styles.chart}>
                    <PieChart
                        animationDuration={500}
                        animationEasing="ease-out"
                        cx={50}
                        cy={50}
                        data={issues}
                        label={false}
                        labelPosition={50}
                        lengthAngle={360}
                        lineWidth={40}
                        onClick={undefined}
                        onMouseOut={undefined}
                        onMouseOver={undefined}
                        paddingAngle={2}
                        radius={50}
                        rounded={false}
                        startAngle={0}
                        viewBoxSize={[
                            100,
                            100,
                        ]}
                    />
                </div>
                <ul className={styles.legend}>
                    <table>
                        <thead>
                        <tr>
                            <th>Всего взято в работу:</th>
                            <th>{total} {declOfNum(total, ['заявка', 'заявки', 'заявок'])}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            issues.map((issue) => (
                                <LegendItem
                                    key={issue.color}
                                    color={issue.color}
                                    title={issue.title}
                                    data={`${issue.value} (${(((issue.value / issuesAmount) || 0) * 100).toFixed(1)}%)`}
                                />
                            ))
                        }
                        </tbody>
                    </table>
                </ul>
            </div>
            <CssLoader/>
        </section>
    );
}

export default issuesByStatus;
