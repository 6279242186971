import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getRegionsRating} from '../../actions/regionsRating.actions';
import RegionsRating from './regionsRating.component';

const perPage = 10;

function RegionsRatingContainer(props) {
    const {dateFilters, getRegionsRating, regionsRatingData} = props;
    const [nextPage, setNextPage] = useState(1);
    const dataLoading = !regionsRatingData.items.length || regionsRatingData.isFetching;

    useEffect(() => {
        const filters = {
            order_by_statistic: 'total',
            order_direction: 'desc',
            per_page: perPage * nextPage,
            location_level: 1
        };

        getRegionsRating({...dateFilters, ...filters});
    }, [dateFilters, getRegionsRating, nextPage]);

    return (
        <RegionsRating
            regionsRating={regionsRatingData.items}
            nextPage={setNextPage}
            perPage={perPage}
            dataLoading={dataLoading}
            meta={regionsRatingData.meta}
        />
    );
}

const mapStateToProps = (state) => {
    const {
        statistic: {
            regionsRating: regionsRatingData,
        },
    } = state;

    return {regionsRatingData};
};

export default connect(mapStateToProps, {
    getRegionsRating,
})(RegionsRatingContainer);
